<template>
	<div class="container">
		<div class="canvas-sec" v-show="false">
			<img src="../assets/invite2.png" ref='invite2img' crossorigin="anonymous" class="invite2-img">
			<img src="../assets/good1.png" ref='headimg' crossorigin="anonymous" class="head-img" />
			<img :src="qrcodeImg" ref='qrcodeimg' crossorigin="anonymous" class="qrcode-img" />
			<canvas id='mycanvas' class="mycanvas share-img" />
		</div>
		<div ref="qrCodeDiv" style="position: fixed;top: -9999px;left: -9999px;"></div>
		<img src="../assets/invite1.png" class="step-img1">
		<div class="wrap">
			<div class="step-wrap2">
				<div class="flex-row align-center title">
					<img src="../assets/icon-step.png" class="icon-step-img">
					<div class="step-num">02</div>
					<div class="step-name">邀请海报</div>
				</div>
				<img :src="base64_img" class="invite-poster">
				<img src="../assets/save-btn.png" class="save-btn" @click="donwloadImg() ">
			</div>
			<div class="step-wrap3">
				<div class="flex-row align-center title">
					<img src="../assets/icon-step.png" class="icon-step-img">
					<div class="step-num">03</div>
					<div class="step-name">我邀请的好友【{{subCount}}】</div>
				</div>
				<div>
					<van-list v-model="loading" @load="myInviteList" :finished="finished" finished-text=" "
						:error.sync="error" error-text="请求失败，点击重新加载">
						<div class="list-item flex-row align-center" v-for="(item,index) in list" v-bind:key="index">
							<img v-if="item.pic" :src="item.pic" class="headimg">
							<img v-else src="../assets/good1.png" class="headimg">
							<div class="flex-column user-wrap">
								<div class="username">{{item.userName}}</div>
								<div class="time">注册时间：{{item.date}}</div>
							</div>
							<div class="num-wrap">
								<block v-if="subCount-index<10">0</block>{{subCount-index}}
							</div>
						</div>
					</van-list>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	import config from '../config/config.js';
	import util from '../config/util.js';
	import QRCode from 'qrcodejs2';
	import base64Img from 'base64-img-promise';
	export default {
		data() {
			return {
				list: [],
				pageNum: 1,
				pageSize: 10,
				loading: false,
				finished: false,
				error: false,
				subCount: '',
				qrcodeImg: '',
				base64_img: ''
			}
		},
		mounted() {
			this.getMember();
			this.myInviteNum();
			Dialog.alert({
				message: '本期活动已结束',
			}).then(() => {
				console.log(111)
				// on close
			});
			this.myInviteList();
		},
		methods: {
			async makeCanvas() {
				let that = this;
				var headimg = that.$refs.headimg;
				if (that.user.pic) {
					const data = await base64Img.requestBase64(that.user.pic)
					if (data.data) {
						headimg = data.data;
					}
				}
				var c = document.getElementById("mycanvas");
				console.log(c)
				var ctx = c.getContext("2d");
				c.width = 643;
				c.height = 1114;
				ctx.fillStyle = '#000000';
				ctx.fillRect(0, 0, 643, 1114);
				var invite2img = that.$refs.invite2img;

				var qrcodeimg = that.$refs.qrcodeimg;
				console.log(invite2img);
				console.log(headimg)
				console.log(qrcodeimg)
				// 根据名字计算需要的宽度,确定名字头像部分的xy坐标
				let name = that.user.name;
				ctx.font = "28px Arial";
				let nameWidth = ctx.measureText(name).width;
				console.log('nameWidth', nameWidth)
				let allWidth = 4 + 50 / 2 + 42 + 25 + nameWidth;
				let leftmargin = (c.width - allWidth) / 2;
				let headimgx = 4 + leftmargin;
				let headimgy = 187;
				let headimgRX = headimgx + 50 / 2;
				let headimgRY = headimgy + 50 / 2;
				let rectX = headimgx + 50 / 2;
				let rectY = 185;
				let rectRightX = rectX + nameWidth + 42;
				let nameX = rectX + 42;
				let nameY = 197 + 3;
				// let intel = setTimeout(() => {

				// }, 1500)
				if (invite2img.complete) {
					qrcodeimg.onload = () => {
						ctx.drawImage(invite2img, 0, 0, 643, 1114);
						// 矩形
						ctx.strokeStyle = "#6236FF";
						ctx.fillStyle = '#6236FF';
						ctx.beginPath();
						ctx.strokeStyle = "#6236FF";
						ctx.fillStyle = '#6236FF';
						ctx.moveTo(rectX, rectY); //左上角
						ctx.lineTo(rectRightX, rectY);
						ctx.lineTo(rectRightX, rectY + 55);
						ctx.lineTo(rectX, rectY + 55);
						ctx.closePath()
						ctx.stroke();
						ctx.fill()
						ctx.fillStyle = '#6236FF';


						ctx.beginPath();
						ctx.fillStyle = '#6236FF';
						// ctx.fillStyle = 'red';
						let r = 55 / 2
						ctx.arc(rectRightX, rectY + r, r, Math.PI * 1.5, Math.PI * 2); //右上角
						ctx.arc(rectRightX, rectY + r, r, Math.PI * 0, Math.PI * 0.5); //右下角
						// ctx.arc(rectRightX, rectY + r, r,  0, 2 * Math.PI); //右上角
						ctx.closePath()
						ctx.fill()

						// 头像背景
						ctx.fillStyle = '#FFFFFF';
						// ctx.fillStyle = 'red';
						ctx.beginPath();
						ctx.arc(headimgRX, headimgRY, 29, 0, 2 * Math.PI);
						ctx.closePath()
						ctx.fill()

						// 头像
						ctx.save()
						ctx.beginPath();
						ctx.arc(headimgRX, headimgRY, 25, 0, 2 * Math.PI);
						ctx.clip()
						ctx.drawImage(headimg, headimgx, headimgy, 50, 50);
						// ctx.drawImage(qrcodeimg, headimgx, headimgy, 50, 50);

						ctx.restore()

						// 名字
						ctx.fillStyle = '#FFFFFF';
						ctx.font = "28px Arial";
						ctx.textAlign = "left";
						ctx.textBaseline = "top";
						ctx.fillText(name, nameX, nameY);

						ctx.drawImage(qrcodeimg, 30, 851, 230, 230);
						setTimeout(function() { //兼容苹果手机，延时函数
							var base64_img = c.toDataURL("image/png");
							that.base64_img = base64_img
						}, 0)
					}
				} else {
					var intel = setTimeout(() => {
						ctx.drawImage(invite2img, 0, 0, 643, 1114);
						// 矩形
						ctx.strokeStyle = "#6236FF";
						ctx.fillStyle = '#6236FF';
						ctx.beginPath();
						ctx.strokeStyle = "#6236FF";
						ctx.fillStyle = '#6236FF';
						ctx.moveTo(rectX, rectY); //左上角
						ctx.lineTo(rectRightX, rectY);
						ctx.lineTo(rectRightX, rectY + 55);
						ctx.lineTo(rectX, rectY + 55);
						ctx.closePath()
						ctx.stroke();
						ctx.fill()
						ctx.fillStyle = '#6236FF';


						ctx.beginPath();
						ctx.fillStyle = '#6236FF';
						// ctx.fillStyle = 'red';
						let r = 55 / 2
						ctx.arc(rectRightX, rectY + r, r, Math.PI * 1.5, Math.PI * 2); //右上角
						ctx.arc(rectRightX, rectY + r, r, Math.PI * 0, Math.PI * 0.5); //右下角
						// ctx.arc(rectRightX, rectY + r, r,  0, 2 * Math.PI); //右上角
						ctx.closePath()
						ctx.fill()

						// 头像背景
						ctx.fillStyle = '#FFFFFF';
						// ctx.fillStyle = 'red';
						ctx.beginPath();
						ctx.arc(headimgRX, headimgRY, 29, 0, 2 * Math.PI);
						ctx.closePath()
						ctx.fill()

						// 头像
						ctx.save()
						ctx.beginPath();
						ctx.arc(headimgRX, headimgRY, 25, 0, 2 * Math.PI);
						ctx.clip()
						ctx.drawImage(headimg, headimgx, headimgy, 50, 50);
						// ctx.drawImage(qrcodeimg, headimgx, headimgy, 50, 50);

						ctx.restore()

						// 名字
						ctx.fillStyle = '#FFFFFF';
						ctx.font = "28px Arial";
						ctx.textAlign = "left";
						ctx.textBaseline = "top";
						ctx.fillText(name, nameX, nameY);

						ctx.drawImage(qrcodeimg, 30, 851, 230, 230);
						setTimeout(function() { //兼容苹果手机，延时函数
							var base64_img = c.toDataURL("image/png");
							that.base64_img = base64_img
						}, 0)
					}, 1500)
				}
				console.log('invite2img.complete' + invite2img.complete)
				invite2img.onload = () => {
					console.log('onnload')
				}
				invite2img.onload = function() {
					console.log('图片显示')
					clearTimeout(intel);
					console.log('onload')
					ctx.drawImage(invite2img, 0, 0, 643, 1114);
					// 矩形
					ctx.strokeStyle = "#6236FF";
					ctx.fillStyle = '#6236FF';
					ctx.beginPath();
					ctx.strokeStyle = "#6236FF";
					ctx.fillStyle = '#6236FF';
					ctx.moveTo(rectX, rectY); //左上角
					ctx.lineTo(rectRightX, rectY);
					ctx.lineTo(rectRightX, rectY + 55);
					ctx.lineTo(rectX, rectY + 55);
					ctx.closePath()
					ctx.stroke();
					ctx.fill()
					ctx.fillStyle = '#6236FF';


					ctx.beginPath();
					ctx.fillStyle = '#6236FF';
					// ctx.fillStyle = 'red';
					let r = 55 / 2
					ctx.arc(rectRightX, rectY + r, r, Math.PI * 1.5, Math.PI * 2); //右上角
					ctx.arc(rectRightX, rectY + r, r, Math.PI * 0, Math.PI * 0.5); //右下角
					// ctx.arc(rectRightX, rectY + r, r,  0, 2 * Math.PI); //右上角
					ctx.closePath()
					ctx.fill()

					// 头像背景
					ctx.fillStyle = '#FFFFFF';
					// ctx.fillStyle = 'red';
					ctx.beginPath();
					ctx.arc(headimgRX, headimgRY, 29, 0, 2 * Math.PI);
					ctx.closePath()
					ctx.fill()

					// 头像
					ctx.save()
					ctx.beginPath();
					ctx.arc(headimgRX, headimgRY, 25, 0, 2 * Math.PI);
					ctx.clip()
					ctx.drawImage(headimg, headimgx, headimgy, 50, 50);
					// ctx.drawImage(qrcodeimg, headimgx, headimgy, 50, 50);

					ctx.restore()

					// 名字
					ctx.fillStyle = '#FFFFFF';
					ctx.font = "28px Arial";
					ctx.textAlign = "left";
					ctx.textBaseline = "top";
					ctx.fillText(name, nameX, nameY);

					ctx.drawImage(qrcodeimg, 30, 851, 230, 230);
					setTimeout(function() { //兼容苹果手机，延时函数
						var base64_img = c.toDataURL("image/png");
						that.base64_img = base64_img
					}, 0)

				}

			},
			myInviteList() {
				const that = this;
				that.loading = true;
				let params = new FormData()
				params.append('pageNum', that.pageNum);
				params.append('pageSize', that.pageSize);
				this.axios.post(config.requestUrl + '/front/user/myInviteList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast.clear();
						let newList = response.data.obj.list
						for (let i in newList) {
							newList[i].date = util.formatDate(newList[i].createTime.replace(/-/g, "/"))
						}
						let list = this.list;
						let finished = false;
						if (response.data.obj.isLastPage) finished = true;
						list = list.concat(newList);
						if (list.length == 0) this.empty = true;
						this.list = list;
						this.pageNum = this.pageNum + 1;
						that.finished = finished;
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
						that.error = true;
					}
					this.loading = false;
				}, response => {
					Toast('获取失败，请稍后重试');
					that.error = true;
					this.loading = false;
				})
			},
			myInviteNum() {
				const that = this;
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/user/myInvite', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.subCount = response.data.obj.subCount
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
						that.error = true;
					}
					this.loading = false;
				}, response => {
					Toast('获取失败，请稍后重试');
					that.error = true;
					this.loading = false;
				})
			},
			getMember() {
				const that = this
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/user/baseInfo', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					this.getBox = 0;
					if (response.data.code == 0) {
						this.user = response.data.obj.userInfo;
						this.user.randName = this.$store.state.user.randName;
						if (!this.$store.state.user.ranName) {
							let user = {
								token: this.$store.state.user.token,
								randName: util.randomString(),
								userPic: this.user.pic,
								nickName: this.user.name
							}
							this.$store.commit('setUser', user)
							localStorage.setItem('user', JSON.stringify(user))
						}
						let qrcode = new QRCode(that.$refs.qrCodeDiv, {
							text: config.host + '?inviteCode=' + that.user.inviteCode,
							width: 200,
							height: 200,
							colorDark: "#000000", //二维码颜色
							colorLight: "#FFFFFF", //二维码背景色
							correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
						})
						let canvas = qrcode._el.querySelector(
							"canvas"); //获取生成二维码中的canvas，并将canvas转换成base64
						that.qrcodeImg = canvas.toDataURL("image/png");
						that.makeCanvas();
						Toast.clear()
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);

					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			donwloadImg() {
				if (util.isWx()) {
					Toast('请长按图片保存')
				} else {
					var aLink = document.createElement('a');
					var blob = this.base64ToBlob(this.base64_img); //new Blob([content]);
					var evt = document.createEvent("HTMLEvents");
					evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
					aLink.download = 'invite.png';
					aLink.href = URL.createObjectURL(blob);
					// aLink.dispatchEvent(evt);
					aLink.click()
				}
			},
			base64ToBlob(code) {
				var parts = code.split(';base64,');
				var contentType = parts[0].split(':')[1];
				var raw = window.atob(parts[1]);
				var rawLength = raw.length;

				var uInt8Array = new Uint8Array(rawLength);

				for (var i = 0; i < rawLength; ++i) {
					uInt8Array[i] = raw.charCodeAt(i);
				}
				return new Blob([uInt8Array], {
					type: contentType
				});
			}
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		background: #150D27;
		overflow: visible;
		/*height: 100%;*/
		min-height: 100%;
	}

	.wrap {
		width: 100%;
		background: linear-gradient(180deg, #150D27 0%, #30284D 100%);
		padding-bottom: 38px;
	}

	.step-img1 {
		width: 750px;
		height: 1835px;
	}

	.step-wrap2 {
		width: 680px;
		height: 1377px;
		background: linear-gradient(144deg, #DBBC8E 0%, #BA8102 100%);
		border-radius: 14px;
		margin: 24px auto 0;
		padding-top: 25px;
		box-sizing: border-box;
	}

	.title {
		width: 687px;
		height: 77px;
		margin-left: -7px;
		/* background-image: url(../assets/icon-step-bg.png); */
		background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
		background-size: 687px 77px;
		position: relative;
	}

	.icon-step-img {
		width: 104px;
		height: 77px;
	}

	.step-num {
		position: absolute;
		left: 22px;
		top: 0;
		line-height: 77px;
		font-size: 40px;
		font-weight: 600;
		color: #FFFFFF;
	}

	.step-name {
		font-size: 36px;
		color: #FFFFFF;
		margin-left: 28px;
		line-height: 77px;
	}

	.invite-poster {
		display: flex;
		width: 643px;
		height: 1114px;
		margin: 22px auto 28px;
		border-radius: 10px;
	}

	.save-btn {
		display: flex;
		width: 308px;
		height: 82px;
		margin: auto;
	}

	.step-wrap3 {
		width: 680px;
		background-image: url(../assets/invite3.png);
		background-size: 680px 1350px;
		background-repeat: repeat-y;
		padding: 25px 0 48px;
		margin: 50px auto 0;
		border-radius: 14px;
	}

	.list-item {
		margin-bottom: 30px;
		width: 686px;
		height: 140px;
		background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
		margin-left: -6px;
		position: relative;
	}

	.headimg {
		width: 70px;
		height: 70px;
		margin-left: 40px;
		border-radius: 100px;
	}

	.user-wrap {
		width: 500px;
		margin-left: 20px;
	}

	.username {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		margin-bottom: 21px;
	}

	.time {
		font-size: 24px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
	}

	.num-wrap {
		height: 61px;
		background: linear-gradient(144deg, #C3996B 0%, #7B5608 100%);
		border-radius: 100px 0px 0px 100px;
		line-height: 61px;
		padding: 0 7px 0 26px;
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.list-item:first-child {
		margin-top: 40px;
	}

	.test {
		display: block;
		margin: auto;
		width: 643px;
		font-size: 28px;
	}

	.invite2-img {
		width: 643px;
		height: 1114px;
		display: none;
	}

	.head-img {
		width: 50px;
		height: 50px;
		display: none;
	}

	.qrcode-img {
		width: 230px;
		height: 230px;
		display: none;
	}

	.share-img {
		width: 643px;
		height: 1114px;
	}

	
</style>
